<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal_main_organization"
      ref="new_record_modal_main_organization"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal_main_organization"
    >
      <div>
        <!-- form -->
        <validation-observer
          ref="addForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="new_record_add_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('organization_management.organization_name') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="main_organization_name"
                    rules="required"
                  >
                    <b-form-input
                      id="main_organization_name"
                      v-model="main_organization_name"
                      :state="errors.length > 0 ? false: main_organization_name != null ? true : null"
                      :placeholder="$t('organization_management.organization_name')"
                      name="main_organization_name"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="mt=1">
              <b-col class="justify-content-end text-right">
                <b-button
                  size="sm"
                  variant="primary"
                  type="submit"
                  :disabled="invalid"
                >
                  <span class="align-middle">{{ $t('core.btn_add') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_record_modal"
      ref="update_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_update')"
      footer-class="d-none"
      size="md"
      @hide="close_edit_record_modal"
    >
      <div>
        <!-- form -->
        <validation-observer
          ref="updateForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="record_edit_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('organization_management.organization_name') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="main_organization_name"
                    rules="required"
                  >
                    <b-form-input
                      id="main_organization_name"
                      v-model="edit_organization_name"
                      :state="errors.length > 0 ? false: edit_organization_name != null ? true : null"
                      :placeholder="$t('organization_management.organization_name')"
                      name="main_organization_name"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row class="mt=1">
              <b-col class="justify-content-end text-right">
                <b-button
                  size="sm"
                  variant="primary"
                  type="submit"
                  :disabled="invalid"
                >
                  <span class="align-middle">{{ $t('core.btn_update') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->
    <b-row>

      <!--      &lt;!&ndash; Per Page &ndash;&gt;-->
      <!--      <b-col-->
      <!--          cols="12"-->
      <!--          md="6"-->
      <!--          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
      <!--      >-->
      <!--        -->
      <!--        <v-select-->
      <!--            v-model="perPage"-->
      <!--            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
      <!--            :options="perPageOptions"-->
      <!--            :clearable="false"-->
      <!--            class="per-page-selector d-inline-block mx-50"-->
      <!--        />-->
      <!--        -->
      <!--      </b-col>-->
      <!-- Search -->
      <b-col
        cols="12"
        class="d-flex justify-content-end"
      >
        <div class="d-flex align-items-center justify-content-end mr-1">
          <b-form-input
            v-model="searchQuery"
            size="sm"
            class="d-inline-block mr-1"
            :placeholder="$t('core.search')"
          />
          <b-button
            v-if="$can('manage', 'organization_management')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.new_record_modal_main_organization
            size="sm"
            variant="primary"
          >
            <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mx-1">
      <div v-if="!$can('manage', 'organization_management')&&organization_data.length==0">
        <b-alert variant="warning" show>
          <h4 class="alert-heading">
            {{ $t('organization_management.access_error') }}
          </h4>
          <div class="alert-body">
            <span>{{ $t('organization_management.no_organization_you_can_access') }}</span>
            <br>
            <span>{{ $t('organization_management.please_contact_the_system_administrator') }}</span>
          </div>
        </b-alert>
      </div>
      <div v-if="$can('manage', 'organization_management')&&organization_data.length==0&&searchQuery==''">
        <b-alert variant="warning" show>
          <h4 class="alert-heading">
            {{ $t('organization_management.new_organization_create') }}
          </h4>
          <div class="alert-body">
            <span><b-button
                v-if="$can('manage', 'organization_management')"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.new_record_modal_main_organization
                size="sm"
                variant="primary"
            >
            <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
          </b-button> {{ $t('organization_management.new_organization_create') }} {{ $t('organization_management.you_can_create_an_organization_by_pressing_the_button') }}</span>
          </div>
        </b-alert>
      </div>
    </b-row>
    <b-row class="justify-content-start mt-25 mx-1">
      <b-col
        v-for="(organization,index) in organization_data"
        v-show="organization_data != null"
        :key="index"
        cols="3"
        class="mb-1"
      >
        <b-card>
          <b-row
            v-if="$can('manage', 'organization_management')"
            class="justify-content-end"
          >
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="rowUpdateModal(organization.id)">
                <feather-icon icon="Edit2Icon" />
                <span class="align-middle ml-50">{{ $t('organization_management.btn_edit') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-row>
          <b-row class="justify-content-center">
            <h4>{{ organization.organization_name }}</h4>
          </b-row>
          <b-row class="justify-content-center mt-50">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="$router.push({ name: 'organization-detail', params: { id: organization.id }})"
            >
              <span class="align-middle">{{ $t('organization_management.show_organization') }}</span>
              <feather-icon
                icon="ChevronsRightIcon"
                class="ml-50"
              />
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--    <b-row>-->
    <!--      <b-col-->
    <!--          cols="12"-->
    <!--          sm="6"-->
    <!--          class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
    <!--      >-->
    <!--        &lt;!&ndash;          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>&ndash;&gt;-->
    <!--      </b-col>-->
    <!--      &lt;!&ndash; Pagination &ndash;&gt;-->
    <!--      <b-col-->
    <!--          cols="12"-->
    <!--          sm="6"-->
    <!--          class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
    <!--      >-->
    <!--        <b-pagination-->
    <!--            v-model="currentPage"-->
    <!--            :total-rows="totalRows"-->
    <!--            :per-page="perPage"-->
    <!--            first-number-->
    <!--            last-number-->
    <!--            class="mb-0 mt-1 mt-sm-0"-->
    <!--            prev-class="prev-item"-->
    <!--            next-class="next-item"-->
    <!--        >-->
    <!--          <template #prev-text>-->
    <!--            <feather-icon-->
    <!--                icon="ChevronLeftIcon"-->
    <!--                size="18"-->
    <!--            />-->
    <!--          </template>-->
    <!--          <template #next-text>-->
    <!--            <feather-icon-->
    <!--                icon="ChevronRightIcon"-->
    <!--                size="18"-->
    <!--            />-->
    <!--          </template>-->
    <!--        </b-pagination>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

  </div>

</template>
<script>
import {
  BAlert,
  BButton,
  BModal,
  VBModal,
  BTabs,
  BTab,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BCard,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSpinbutton, BBreadcrumbItem, BCardText, BPagination, BCardHeader, BCardTitle, BDropdownItem, BDropdown, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import OrganizationRenderer from '@/views/custom_app/client/organization_management/components/OrganizationRenderer.vue'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BDropdown,
    BDropdownItem,
    BCardTitle,
    BCardHeader,
    BPagination,
    vSelect,
    BCardText,
    BBreadcrumbItem,
    BCard,
    BAlert,
    BButton,
    BCol,
    BTab,
    BTabs,
    BContainer,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BFormSpinbutton,
    OrganizationRenderer,
    BFormRadio,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPageOptions: [25, 50, 100, 500, 1000],
      sortBy: null,
      isSortDirDesc: null,
      searchQuery: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 500,
      selected_row_id: null,
      main_organization_name: null,
      edit_organization_name: null,
      organization_data: [],
    }
  },
  watch: {
    currentPage: { handler() { this.getTableData() } },
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      useJwt.getCompanyOrganizationList({
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.organization_data = response.data.results
          this.totalRows = response.data.count
          if (this.$can('manage', 'organization_management') == false && this.totalRows == 1) {
            this.$router.push({ name: 'organization-detail', params: { id: this.organization_data[0].id } })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      this.$refs.addForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('company', getUserData().company_id)
          formData.append('organization_name', this.main_organization_name)
          useJwt.createCompanyOrganization(formData)
            .then(response => {
              this.$router.push({ name: 'organization-detail', params: { id: response.data.organization_id } })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.record_added'),
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
            })
          this.close_new_record_modal_main_organization()
        }
      })
    },
    close_new_record_modal_main_organization() {
      this.main_organization_name = null
      this.$refs.new_record_modal_main_organization.hide()
    },
    rowUpdateModal(row_id) {
      useJwt.CompanyOrganizationDetail(row_id)
        .then(response => {
          this.edit_organization_name = response.data.organization_name
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_edit_button() {
      this.$refs.updateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('organization_name', this.edit_organization_name)
          useJwt.updateCompanyOrganization(this.selected_row_id, formData)
            .then(response => {
              this.getTableData()
              this.close_edit_record_modal()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.record_updated'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertIcon',
                  variant: 'danger',
                  text: error.response.data.detail,
                },
              })
            })
        }
      })
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.selected_row_id = null
    },
  },
}
</script>
